import Observable from "../observable"
import sanitizeHtml from "sanitize-html"

/**
 * Alert
 * params: {
 *   alert: fn(args, [type])
 *   validations: [
 *     {
 *       key => String used to search for key of passed prop i.e. "title|text"
 *       value => { required: bool, text: string, validator: fn }
 *     }
 *   ]
 * }
 */
class Alert {
  warnings = new Observable([])

  constructor({ alert, validations }) {
    this.alert = alert
    this.validations = validations
    this.warnings.subscribe(this.onWarning)
  }

  fire = (args, type) => this.alert(this.validate(this.sanitize(args)), type)

  findFieldName = ({ search, args }) =>
    Object.keys(args).find(name => new RegExp(search).test(name))

  validate = args => {
    const fields = Object.entries(this.validations).map(([search, opts]) => {
      const fieldName = this.findFieldName({ args, search })
      const value = args[fieldName]
      const include = fieldName || opts.required
      return include ? { value, ...opts } : null
    })
    const warnings = fields
      .filter(({ value, validator }) => {
        return !validator(value)
      })
      .map(({ text }) => text)
    if (warnings.length) this.warnings.set(warnings)
    return args
  }

  sanitize = args =>
    Object.entries(args).reduce((acc, [key, val]) => {
      if (["title", "confirmButtonText", "cancelButtonText"].includes(key)) {
        acc[key] = sanitizeHtml(val)
      } else {
        acc[key] = val
      }
      return acc
    }, {})

  onWarning = (warnings = []) =>
    // eslint-disable-next-line no-console
    console.warn(
      "** PCO ALERT config mismatch: **",
      warnings,
      "for more information: https://planningcenter.design/prompts"
    )

  /** ACTIONS */
  confirm = args => this.fire(args, "confirm")
  confirmCreate = args => this.fire(args, "confirmCreate")
  confirmDestroy = args => this.fire(args, "confirmDestroy")
  error = args => this.fire(args, "error")
  info = args => this.fire(args, "info")
  success = args => this.fire(args, "success")
  warn = args => this.fire(args, "warn")
}

export default Alert
