import Swal from "sweetalert2/dist/sweetalert2"
import {
  createConfirmOptions,
  destroyConfirmOptions,
  errorAlertOptions,
  standardAlertOptions,
  standardConfirmOptions,
  successAlertOptions,
} from "@planningcenter/sweetalert2"

const options = {
  confirm: standardConfirmOptions,
  confirmCreate: createConfirmOptions,
  confirmDestroy: destroyConfirmOptions,
  error: errorAlertOptions,
  info: standardAlertOptions,
  success: successAlertOptions,
  warn: standardConfirmOptions,
}

const sweetAlert = (args, type = "confirm") =>
  Swal.mixin({ ...options[type], heightAuto: false }).fire(args)

const validations = {
  "html|text": {
    // Body must be present
    required: true,
    text: "Text must be present",
    validator: v => !!v,
  },
  title: {
    // Title must be present
    required: true,
    text: "Title must be present",
    validator: v => !!v,
  },
}

const defaultConfig = {
  alert: sweetAlert,
  validations,
}

export { defaultConfig }
