import React from "react"
import { Symbol } from "@planningcenter/symbol"

import accounts from "@planningcenter/icons/sprites/accounts.svg"
import cco from "@planningcenter/icons/sprites/cco.svg"
import check_ins from "@planningcenter/icons/sprites/check-ins.svg"
import general from "@planningcenter/icons/sprites/general.svg"
import giving from "@planningcenter/icons/sprites/giving.svg"
import groups from "@planningcenter/icons/sprites/groups.svg"
import people from "@planningcenter/icons/sprites/people.svg"
import registrations from "@planningcenter/icons/sprites/registrations.svg"
import logomark from "@planningcenter/icons/sprites/logomark.svg"
import brand from "@planningcenter/icons/sprites/brand.svg"

let icons = {
  accounts,
  cco,
  check_ins,
  general,
  giving,
  groups,
  people,
  registrations,
  logomark,
  brand,
}

function ExternalIcon({ symbol: s, pathPrefix, ...platformProps }) {
  const [collection, symbol] = s.replace(".svg", "").split("#")

  return (
    <Symbol
      symbol={`${pathPrefix}${icons[collection]}#${symbol}`}
      {...platformProps}
    />
  )
}

ExternalIcon.defaultProps = {
  pathPrefix: "",
}

export default ExternalIcon
